import request from './request';

function list() {
  return request({
    method: 'GET',
    url: '/projects',
    params: {
      where: {
        deletedDate: null,
        active: true,
      },
      limit: 100,
    },
  });
}

const ProjectService = {
  list,
};

export default ProjectService;
