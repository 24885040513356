import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ClientForm from './modules/ClientForm';
import PictureForm from './modules/PictureForm';
import Review from './modules/Review';
import logo from './logo.svg';
import MySnackbarContent from './components/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import CircularProgress from '@material-ui/core/CircularProgress';

import ProjectService from './api/ProjectService';
import TicketService from './api/TicketService';
import TicketFileService from './api/TicketFileService';
import { reject } from 'q';
import MyTickets from './modules/MyTickets';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: red[500],
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: green[500],
        },
        '&$active': {
          color: red[500],
        },
      },
      active: {},
      completed: {},
    },
  },
});

const styles = (theme) =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    alternativeLabel: {},
    active: {}, //needed so that the &$active tag works
    completed: {},
    disabled: {},
    labelContainer: {
      '& $alternativeLabel': {
        marginTop: 0,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
  });

const dataSteps = [
  {
    name: 'nameOnError',
    email: 'emailOnError',
    phone: 'phoneOnError',
    project: 'projectOnError',
    apartment: 'apartmentOnError',
    claim: 'claimOnError',
  },
];

const steps = ['Datos Personales', 'Cargar imagenes', 'Revisar su solicitud'];

class App extends React.Component {
  state = {
    myTickets: false,
    loading: false,
    activeStep: 0,
    disableStep0: true,
    disableStep1: false,
    disableStep2: false,
    showAlert: false,
    projects: [],
    step1: {
      name: '',
      nameOnError: true,
      email: '',
      emailOnError: true,
      phone: '',
      phoneOnError: true,
      project: 0,
      projectOnError: true,
      apartment: '',
      apartmentOnError: true,
      claim: '',
      claimOnError: true,
      status: 1,
    },
    step2: {
      images: [],
    },
  };

  async componentDidMount() {
    await ProjectService.list().then((response) => {
      if (response.status === 200) {
        this.setState({
          projects: response.data,
        });
      }
    });
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ClientForm
            getData={this.getData}
            data={this.state.step1}
            projects={this.state.projects}
          />
        );
      case 1:
        return (
          <PictureForm
            getImages={this.getImages}
            images={this.state.step2.images}
          />
        );
      case 2:
        return (
          <Review
            step1={this.state.step1}
            projects={this.state.projects}
            images={this.state.step2.images}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  getImages = (images) => {
    // const disabled = images.length > 0 ? false : true;
    let disabled = false;
    this.disableButtonByStep(disabled);

    this.setState({
      step2: {
        images,
      },
    });
  };

  getData = (data) => {
    if (this.state.activeStep === 0) {
      if (
        !data[dataSteps[this.state.activeStep].name] &&
        !data[dataSteps[this.state.activeStep].phone] &&
        !data[dataSteps[this.state.activeStep].project] &&
        !data[dataSteps[this.state.activeStep].email] &&
        !data[dataSteps[this.state.activeStep].apartment]
      ) {
        this.disableButtonByStep(false);
        this.setState({
          step1: data,
        });
      } else {
        this.disableButtonByStep(true);
        this.setState({
          step1: {},
        });
      }
    } else {
      throw new Error('Unknown step');
    }
  };

  disableButtonByStep = (disable) => {
    this.setState({
      ['disableStep' + String(this.state.activeStep)]: disable,
    });
  };

  handleNext = () => {
    if (this.state.activeStep === steps.length - 1) {
      this.disableButtonByStep(true);
      this.setState({
        loading: true,
      });
      TicketService.create(this.state.step1).then(
        (response) => {
          if (response.status === 200) {
            let ticket = response.data;

            if (this.state.step2.images.length === 0) {
              const emailData = {
                name: this.state.step1.name,
                email: this.state.step1.email,
                ticketId: ticket.id,
              };
              return this.emailConfirmation(emailData, ticket);
            }

            return Promise.all(
              this.state.step2.images.map((file) => {
                return new Promise((resolve) => {
                  const formData = new FormData();
                  formData.append('id', ticket.id);
                  formData.append('file', file);
                  TicketService.upload(formData).then(
                    (fileResponse) => {
                      if (fileResponse.status === 200) {
                        let data = {
                          ticket: ticket.id,
                          file: fileResponse.data.url,
                        };
                        TicketFileService.create(data).then(
                          (_) => {
                            resolve();
                          },
                          (rejected) => {
                            reject(rejected);
                          }
                        );
                      } else {
                        reject(fileResponse.status);
                      }
                    },
                    () => {
                      this.showAlert();
                    }
                  );
                });
              })
            ).then((_) => {
              const emailData = {
                name: this.state.step1.name,
                email: this.state.step1.email,
                ticketId: ticket.id,
              };
              this.emailConfirmation(emailData, ticket);
            });
          }
        },
        (rejected) => {
          this.showAlert();
          this.disableButtonByStep(false);
        }
      );
    } else {
      this.setState((state) => ({
        activeStep: state.activeStep + 1,
      }));
    }
  };

  emailConfirmation = (data, ticket) => {
    TicketService.confirmation(data).then(
      (response) => {
        this.setState((state) => ({
          activeStep: state.activeStep + 1,
          ticket: ticket,
        }));
      },
      (reject) => {
        this.showAlert();
      }
    );
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  showAlert = () => {
    this.setState({ showAlert: true });
  };

  closeAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ showAlert: false });
  };

  render() {
    const { classes } = this.props;
    const { activeStep, myTickets } = this.state;
    return (
      <MuiThemeProvider theme={muiTheme}>
        <React.Fragment>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.showAlert}
            autoHideDuration={6000}
            onClose={this.closeAlert}
          >
            <MySnackbarContent
              onClose={this.closeAlert}
              variant='warning'
              message='Hubo un error, porfavor recargue la pagina e intente nuevamente'
            />
          </Snackbar>
          <CssBaseline />
          <AppBar position='static' color='primary'>
            <Toolbar>
              <img src={logo} width='130' alt='Copahue' />
              <Typography className={classes.grow} />
              <Button
                color='inherit'
                onClick={() => this.setState({ myTickets: true })}
              >
                Mis tickets
              </Button>
            </Toolbar>
          </AppBar>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              {myTickets ? (
                <MyTickets />
              ) : (
                <React.Fragment>
                  <Typography component='h1' variant='h4' align='center'>
                    Solicitud de Postventa
                  </Typography>
                  <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <React.Fragment>
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography variant='h5' gutterBottom>
                          Tu ticket ha sido registrado exitosamente!
                        </Typography>
                        <Typography variant='subtitle1'>
                          Tu numero es #{this.state.ticket.id}. Te hemos enviado
                          un email de confirmación a tu correo. El personal de
                          postventa te contactará para agendar tu visita. Muchas
                          gracias!
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {this.getStepContent(activeStep)}
                        <div className={classes.buttons}>
                          {activeStep !== 0 && !this.state.loading && (
                            <Button
                              onClick={this.handleBack}
                              className={classes.button}
                            >
                              Volver
                            </Button>
                          )}
                          <Button
                            variant='contained'
                            color='secondary'
                            onClick={this.handleNext}
                            className={classes.button}
                            disabled={
                              this.state[
                                'disableStep' + String(this.state.activeStep)
                              ]
                            }
                          >
                            {activeStep === steps.length - 1
                              ? 'Finalizar'
                              : 'Siguiente'}
                            {this.state.loading && (
                              <CircularProgress
                                size={24}
                                style={{
                                  color: green[500],
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  marginTop: -12,
                                  marginLeft: -12,
                                }}
                              />
                            )}
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </React.Fragment>
              )}
            </Paper>
          </main>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
