import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    padding: `${theme.spacing.unit}px 0`
  },
  total: {
    fontWeight: '700'
  },
  title: {
    marginTop: theme.spacing.unit * 2
  },
  gridList: {
    width: 500
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
});
class Review extends React.Component {
  state = {
    images: []
  };
  componentWillMount() {
    this.props.images.map(image => {
      let reader = new FileReader();
      let file = image;
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let img = {
          src: reader.result,
          name: image.name
        };
        this.setState(prevState => ({
          images: [...prevState.images, img]
        }));
      };
      return true;
    });
  }

  images() {
    const { classes } = this.props;
    return (
      <GridList cellHeight={180} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div">Imágenes cargadas</ListSubheader>
        </GridListTile>
        {this.state.images.map((image, key) => (
          <GridListTile key={key}>
            <img src={image.src} alt={image.name} />
            <GridListTileBar
              title={image.name}
              subtitle={<span>por: {this.props.step1.name}</span>}
              actionIcon={
                <IconButton className={classes.icon}>
                  <InfoIcon />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Resumen Ticket
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={6} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Datos de contacto
            </Typography>
            <Typography gutterBottom>{this.props.step1.name}</Typography>
            <Typography gutterBottom>{this.props.step1.email}</Typography>
            <Typography gutterBottom>{this.props.step1.phone}</Typography>
            <Typography gutterBottom>{this.props.step1.claim}</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Datos del Proyecto
            </Typography>
            <Typography gutterBottom>
              {
                this.props.projects.find(
                  project => project.id === this.props.step1.project
                ).name
              }
            </Typography>
            <Typography gutterBottom>
              Nº {this.props.step1.apartment}
            </Typography>
          </Grid>
        </Grid>

        {this.state.images.length > 0 ? this.images() : ''}
      </React.Fragment>
    );
  }
}

Review.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Review);
