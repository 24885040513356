import request from './request';

function create(data) {
  return request({
    method: 'POST',
    url: '/ticketsfiles',
    data
  });
}

const TicketFileService = {
  create,
};

export default TicketFileService;