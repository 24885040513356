import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class PersonalForm extends React.Component {
  state = {
    name: '',
    nameOnError: true,
    email: '',
    emailOnError: true,
    phone: '',
    phoneOnError: true,
    project: 0,
    projectOnError: true,
    apartment: '',
    apartmentOnError: true,
    claim: '',
    claimOnError: true,
    status: 1
  };

  componentDidMount = () => {
    if (
      this.props.data.name !== this.state.name ||
      this.props.data.nameOnError !== this.state.nameOnError ||
      this.props.data.email !== this.state.email ||
      this.props.data.emailOnError !== this.state.emailOnError ||
      this.props.data.phone !== this.state.phone ||
      this.props.data.phoneOnError !== this.state.phoneOnError ||
      this.props.data.project !== this.state.project ||
      this.props.data.projectOnError !== this.state.projectOnError ||
      this.props.data.apartment !== this.state.apartment ||
      this.props.data.apartmentOnError !== this.state.apartmentOnError ||
      this.props.data.claimOnError !== this.state.claimOnError
    ) {
      this.setState({
        ...this.props.data
      });
    }
  };

  handleChange = event => {
    const data = {
      ...this.state,
      [event.target.name]: event.target.value
    };
    this.setState(data);
    this.required(data, event);
  };

  validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  required = (data, event) => {
    if (event.target.required) {
      if (event.target.type && event.target.type === 'email') {
        let error = true;
        if (this.validateEmail(event.target.value)) {
          error = false;
        }
        this.setState({
          [event.target.name + 'OnError']: error
        });
        data = {
          ...data,
          [event.target.name + 'OnError']: error
        };
      } else if (event.target.name === 'phone') {
        let error = true;
        if (event.target.value.length === 9) {
          error = false;
        }
        this.setState({
          [event.target.name + 'OnError']: error
        });
        data = {
          ...data,
          [event.target.name + 'OnError']: error
        };
      } else {
        let error = true;
        if (!event.target.value.length <= 0) {
          error = false;
        }
        this.setState({
          [event.target.name + 'OnError']: error
        });
        data = {
          ...data,
          [event.target.name + 'OnError']: error
        };
      }
    }
    this.props.getData(data);
  };

  handleSelect = event => {
    let data = {
      ...this.state,
      [event.target.name]: event.target.value
    };
    if (event.target.value !== 0) {
      this.setState({
        [event.target.name]: event.target.value,
        [event.target.name + 'OnError']: false
      });
      data = {
        ...data,
        [event.target.name + 'OnError']: false
      };
    } else {
      this.setState({ [event.target.name + 'OnError']: true });
      data = {
        ...data,
        [event.target.name + 'OnError']: false
      };
    }
    this.props.getData(data);
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Datos personales
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="name"
              name="name"
              label="Nombre"
              fullWidth
              value={this.state.name}
              error={this.state.nameOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={this.state.email}
              error={this.state.emailOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="phone"
              name="phone"
              label="Teléfono o Celular (9 dígitos)"
              type="number"
              inputProps={{ maxLength: 9 }}
              fullWidth
              value={this.state.phone}
              error={this.state.phoneOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              required
              name="project"
              displayEmpty
              className={classes.selectEmpty}
              value={this.state.project}
              error={this.state.projectOnError}
              onChange={this.handleSelect}
              fullWidth
            >
              <MenuItem value={0} disabled>
                Seleccione un proyecto
              </MenuItem>
              {this.props.projects.map((project, key) => {
                return (
                  <MenuItem key={key} value={project.id}>
                    {project.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="apartment"
              name="apartment"
              label="Nº Departamento"
              fullWidth
              value={this.state.apartment}
              error={this.state.apartmentOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="claim"
              name="claim"
              label="Observaciones"
              fullWidth
              multiline
              rowsMax="6"
              value={this.state.claim}
              onChange={this.handleChange}
              error={this.state.claimOnError}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PersonalForm);
