import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Button, Snackbar } from '@material-ui/core';
import TicketService from '../api/TicketService';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MySnackbarContent from './../components/Alert';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class MyTicketForm extends React.Component {
  state = {
    showAlert: false,
    email: '',
    emailOnError: true,
    ticket: '',
    ticketOnError: true,
    numberUnit: '',
    numberUnitOnError: true,
    filteredTicket: [],
  };

  componentDidMount = () => {};

  handleChange = (event) => {
    const data = {
      ...this.state,
      [event.target.name]: event.target.value,
    };
    this.setState(data);
    this.required(data, event);
  };

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  required = (data, event) => {
    if (event.target.required) {
      if (event.target.type && event.target.type === 'email') {
        let error = true;
        if (this.validateEmail(event.target.value)) {
          error = false;
        }
        this.setState({
          [event.target.name + 'OnError']: error,
        });
        data = {
          ...data,
          [event.target.name + 'OnError']: error,
        };
      } else {
        let error = true;
        if (!event.target.value.length <= 0) {
          error = false;
        }
        this.setState({
          [event.target.name + 'OnError']: error,
        });
        data = {
          ...data,
          [event.target.name + 'OnError']: error,
        };
      }
    }
  };

  handleSelect = (event) => {
    if (event.target.value !== 0) {
      this.setState({
        [event.target.name]: event.target.value,
        [event.target.name + 'OnError']: false,
      });
    } else {
      this.setState({ [event.target.name + 'OnError']: true });
    }
  };

  searchTicket = async () => {
    const { email, ticket, numberUnit } = this.state;

    if (!email.length) {
      this.setState({
        showAlert: true,
      });
    } else {
      const response = await TicketService.getTicket({
        id: ticket,
        email,
        number: numberUnit,
      });
      const data = response.data;
      this.setState({
        filteredTicket: data,
      });
    }
  };

  render() {
    const { filteredTicket, showAlert } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showAlert}
          autoHideDuration={6000}
          onClose={!showAlert}
        >
          <MySnackbarContent
            onClose={!showAlert}
            variant='warning'
            message='Debe ingresar el correo electronico.'
          />
        </Snackbar>
        <Typography variant='h6' gutterBottom>
          Revisar estado de mi solicitud de postventa
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <TextField
              id='ticket'
              name='ticket'
              label='Número de Ticket'
              fullWidth
              value={this.state.ticket}
              error={this.state.ticketOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id='email'
              name='email'
              label='Email'
              type='email'
              fullWidth
              value={this.state.email}
              error={this.state.emailOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='numberUnit'
              name='numberUnit'
              label='Número de Unidad'
              fullWidth
              value={this.state.numberUnit}
              error={this.state.numberUnitOnError}
              onChange={this.handleChange}
            />
          </Grid>
          <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant='contained'
                style={{ marginTop: 24, marginLeft: 8 }}
                color='secondary'
                onClick={() => this.searchTicket()}
              >
                Buscar
              </Button>
            </div>
          </React.Fragment>
          {filteredTicket.length ? (
            <div
              style={{
                width: '100%',
                marginTop: 10,
                overflowX: 'auto',
              }}
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ticket ID</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Unidad</TableCell>
                    <TableCell>Detalle</TableCell>
                    <TableCell>Proyecto</TableCell>
                    <TableCell>Fecha de Contacto</TableCell>
                    <TableCell>Ultima actualizacion</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTicket.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.apartment_number}</TableCell>
                      <TableCell>{row.claim}</TableCell>
                      <TableCell>{row.project.name}</TableCell>
                      <TableCell>
                        {row.contactDate &&
                          row.contactDate.replace('T', ' ').slice(0, 19)}
                      </TableCell>
                      <TableCell>
                        {row.modified &&
                          row.modified.replace('T', ' ').slice(0, 19)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <React.Fragment />
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MyTicketForm);
