import request from './request';

function create(data) {
  return request({
    method: 'POST',
    url: '/tickets',
    data: {
      ...data,
      project_id: data.project,
      apartment_number: data.apartment,
    },
  });
}

function upload(form) {
  return request({
    method: 'POST',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
    url: '/tickets/upload',
    data: form,
  });
}

function confirmation(data) {
  return request({
    method: 'POST',
    url: '/tickets/confirmation',
    data: data,
  });
}

function getTicket(data) {
  let where = {};

  if (data.id) {
    where = {
      ...where,
      id: data.id,
    };
  }

  if (data.email) {
    where = {
      ...where,
      email: data.email,
    };
  }

  if (data.number) {
    where = {
      ...where,
      apartment_number: data.number,
    };
  }

  const params = {
    where,
    select: 'apartment_number,claim,status,contactDate,modified',
    populate: 'project',
  };

  return request({
    method: 'GET',
    url: '/tickets',
    params,
  });
}

const TicketService = {
  create,
  upload,
  confirmation,
  getTicket,
};

export default TicketService;
