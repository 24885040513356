import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FilePond, registerPlugin, File } from 'react-filepond';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateType);

class PaymentForm extends React.Component {
  state = {
    files: []
  };

  handleInit = () => {};

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Subida de archivos
        </Typography>
        <FilePond
          allowMultiple={true}
          labelIdle="Seleccione sus fotos o videos aquí"
          acceptedFileTypes={['image/png', 'image/jpeg', 'video/mp4']}
          // server={`${process.env.REACT_APP_API_URL}/projects/upload`}
          oninit={() => this.handleInit()}
          // imagePreviewMaxFileSize="100KB"
          onupdatefiles={fileItems => {
            // Set current file objects to this.state
            this.props.getImages(fileItems.map(fileItem => fileItem.file));
            // this.setState({
            //   files: fileItems.map(fileItem => fileItem.file)
            // });
          }}
        >
          {this.props.images.length > 0
            ? this.props.images.map(file => <File key={file} src={file} />)
            : ''}
        </FilePond>
      </React.Fragment>
    );
  }
}

export default PaymentForm;
